<template>
    <results-layout :fluid="false">
        <v-container>
            <div class="text-h5">Overview</div>
            <insights-overview></insights-overview>

            <by-question-insights v-if="showAnalytics"></by-question-insights>
            <v-card v-else class="pa-6 mt-4 mb-8" outlined color="white">
                <Bar v-if="chartData && chartData.datasets.length" :chart-options="chartOptions" :chart-data="chartData"
                :width="400" :height="400" />
                <div style="color: rgba()" class="d-flex flex-column align-center overlay">
                    <div class="text-subtitle-1 mb-6">Curious to see your responses over time?</div>
                    <div class="text-body-1 mb-6">Upgrade your plan to unlock charts and filters!</div>
                    <v-btn elevation="0" :to="{ name: 'dashboard.upgrade'}" color="primary">Upgrade Now</v-btn>
                </div>
            </v-card>
            <div class="text-h5 mt-12">Questions drop-off</div>
            <v-card v-if="showAnalytics" outlined color="white" class="pa-6 mt-4 mb-8">
                <dropoffs></dropoffs>
            </v-card>
            <v-card v-else class="pa-6 mt-4 mb-8" outlined color="white">
                <div v-for="index in 6" :key="`question-placeholder-${index}`" class="d-flex align-center px-4 mb-6">
                    <div class="question-type-badge mr-4"></div>
                    <div style="width: 40%; height: 16px;" class="grey lighten-4"></div>
                    <v-spacer></v-spacer>
                    <div style="width: 50px; height: 24px;" class="error lighten-5"></div>
                </div>
                <div class="d-flex flex-column align-center overlay">
                    <div class="text-subtitle-1 mb-6">Question drop-off rate</div>
                    <div class="text-body-1 mb-6">Know exactly where you're losing responses!</div>
                    <v-btn elevation="0" :to="{ name: 'dashboard.upgrade'}" color="primary">Upgrade my plan</v-btn>
                </div>
            </v-card>
        </v-container>
    </results-layout>
</template>

<script>
import { mapGetters } from 'vuex'
import ResultsLayout from '../../../../layouts/ResultsLayout.vue'
import ByQuestionInsights from './ByQuestionInsights.vue';
import NoQuestions from './NoQuestions.vue';
import Dropoffs from './Dropoffs.vue';
import InsightsOverview from './InsightsOverview.vue';
import { Bar } from 'vue-chartjs/legacy'
export default {
    data() {
        return {
            chartData: {
                labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
                datasets: [{
                    label: 'Trends',
                    data: [12, 5, 3, 5, 2, 19],
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)'
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)'
                    ],
                    borderWidth: 1
                }]
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        beginAtZero: true
                    }
                }
            },
            width: 400,
            height: 400
        };
    },
    computed: {
        ...mapGetters({
            features: 'auth/features'
        }),

        showAnalytics(){
            return this.features && this.features.analytics
        }
    },
    components: { ResultsLayout, NoQuestions, ByQuestionInsights, Dropoffs, InsightsOverview, Bar },

}
</script>

<style scoped>
.overlay{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    flex-grow: 1;
    justify-content: center;
    background: linear-gradient(0deg, white, transparent);
}
.question-type-badge{
    width: 32px;
    height: 32px;
    min-width: 32px;
    justify-content: center;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 4px;

    background-color: var(--v-primary-lighten5);
    color: var(--v-primary-darken1);
}
</style>